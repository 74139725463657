import React, { ReactElement, useRef } from "react";
import printIcon from "../../../assets/img/printIcon.svg";
import downloadIcon from "../../../assets/img/downloadIcon.svg";
import CloseIcon from "../../../assets/img/closeIcon.svg";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import FaanLogo from "../../../assets/img/faan-logo.png";
import callIcon from "../../../assets/img/callWhite.svg";
import messageIcon from "../../../assets/img/messageWhite.svg";
import { toast } from "react-toastify";
import "./credit.css";
import { useMediaQuery } from "react-responsive";

interface CreditTokenModalProps {
  isOpen: boolean;
  setIsOpen: any;
  tokenData: any;
  onClose: () => void;
}

const CreditTokenModal: React.FC<CreditTokenModalProps> = ({
  tokenData,
  isOpen,
  setIsOpen,
  onClose,
}): ReactElement => {
  const printRef = useRef<HTMLDivElement>(null);

  const generatePDF = () => {
    const input = printRef.current;

    if (!input) {
      toast.error("Print reference is not available");
      return;
    }
    // @ts-ignore
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save("receipt.pdf");
    });
  };

  return (
    <>
      {isOpen ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 overflow-auto">
          <div className="md:w-full w-[400px] relative h-[630px] p-4 sm:p-6 md:p-8 lg:w-[830px] md:h-[800px] lg:pl-[180px] lg:pr-[179.65px] lg:pt-[34px] lg:pb-[45px] bg-neutral-50 rounded-[40px] flex flex-col justify-start items-center gap-8 md:mt-[300px] mt-[20px]">
            <div className="text-zinc-900 text-[24px] sm:text-[28px] md:text-[32px] lg:text-[36px] xl:text-[40px] font-bold font-['Uncut Sans Variable'] leading-8 sm:leading-9 md:leading-10 lg:leading-[44px] xl:leading-[48px] mt-2 sm:mt-4 md:mt-6 lg:mt-8">
              Receipt Preview
            </div>

            <div ref={printRef} className="w-full bg-white md:w-[470.35px] h-[550px] md:h-[649px] p-4 sm:p-5 bg-emerald-600/opacity-5 rounded-[20px] flex justify-center items-center sm:justify-start sm:items-start gap-2.5 border-[30px] border-[#f4f9f7] print:w-[70%] print:border-none print:mt-[30%]">
              <img src={FaanLogo} alt="" className=" mb-[140%] md:mb-0 print:mt-[-45%]" />
              <img className="w-[171.28px] h-[79.05px] opacity-5 absolute inset-0 m-auto print:ml-[22%] print:mt-[30%]" src={FaanLogo} alt="" />
              <div className="w-[225px] h-[47px] relative md:top-10 top-[-40%]">
                <div className="top-[45%] absolute whitespace-nowrap text-center right-0 md:right-10 print:mt-[2%]">
                  <span className="text-zinc-900/opacity-70 text-[10px] font-normal">Generated from</span>
                  <span className="text-zinc-900/opacity-70 text-[10px] font-bold"> FAAN</span>
                  <span className="text-zinc-900/opacity-70 text-[10px] font-normal"> on {moment(tokenData.transactionDate).format("DD/MM/YYYY HH:mm:ss")}</span>
                </div>
                <div className="top-2 absolute text-emerald-600 text-sm font-bold font-['Uncut Sans Variable'] print:ml-14">Credit Token Receipt</div>
                <div className="flex flex-col mt-[20%] mr-[7%] print:mt-0 print:mr-0">
                  <div className="text-zinc-900/opacity-80 text-[7.23px] font-semibold font-['Uncut Sans Variable'] ml-[20%] mt-2 md:mt-0 print:ml-[40%] print:mt-[20%]">Generated Token</div>
                  <div className="text-emerald-600 text-[7.67px] font-semibold font-['Uncut Sans Variable'] bg-[#F2FBF7] p-2 rounded-[10px] mr-[37%] ml-[-15] mt-1 print:ml-[30%]">{tokenData.token}</div>
                </div>
              </div>

              <div className="md:w-[383.75px] w-[300px] h-[287.66px] md:left-[27%] left-[17%] top-[37%] md:top-[40%] absolute  print:w-[400px]">
                <div className="md:w-[383.75px] w-[283.75px] h-[287.66px] left-0 top-0 absolute flex-col justify-start items-start gap-[5.78px] inline-flex  print:w-auto print:h-[70%] print:top-[-80%]">
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Customer’s Name</div>
                    <div className="text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.customerName}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Address</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable'] capitalize">{tokenData.address}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Tariff</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.tarrif}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Rate</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.rate}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Account No</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.accountNo}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Meter No</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.meterNo}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Invoice No</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.invoiceNo}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Receipt No</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.receiptNumber}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Transaction Date</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{moment(tokenData.transactionDate).format("YYYY-MM-DD")}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Cost of Units</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.unit}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">VAT</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.vat}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Amount Tendered</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.amountTendered}</div>
                  </div>
                  <div className="self-stretch py-[4.34px] border-b border-zinc-900/opacity-10 justify-between items-center inline-flex ">
                    <div className="text-zinc-900/opacity-80 text-[8.67px] font-medium font-['Uncut Sans Variable']">Units</div>
                    <div className="w-[190.79px] text-right text-zinc-900 text-[8.67px] font-semibold font-['Uncut Sans Variable']">{tokenData.units}</div>
                  </div>
                </div>
              </div>
              <div className="w-[329px] md:w-[420px] h-[42.64px] bg-[#79D1A7] absolute md:bottom-16 bottom-4 rounded-b-lg ml-[-3%] print:ml-[12%] print:mb-[65%] print:w-[290px] print:rounded-none">
                <div className="px-[2.89px] left-[23.13px] pt-3 absolute justify-start items-center gap-[2.89px] inline-flex">
                  <img className="w-[17.34px] h-[17.34px] relative print:w-4 print:h-4" src={callIcon} alt="" />
                  <div className="text-right text-white text-[8.67px] font-normal font-['Uncut Sans Variable']">+234 704 627 0753</div>
                </div>
                <div className="px-[2.89px] left-[148.15px] pt-3 absolute justify-start items-center gap-[2.89px] inline-flex">
                  <img className="w-[17.34px] h-[17.34px] relative print:w-4 print:h-4" src={messageIcon} alt="" />
                  <div className="text-right text-white text-[8.67px] font-normal font-['Uncut Sans Variable']">support@avitechng.com</div>
                </div>
              </div>
            </div>

            {/* nested actions */}
            <div className="absolute md:top-4 md:left-[100%] left-[5%] flex md:flex-col flex-row justify-start items-start gap-4 px-2 bottom-[-10%]">
            {/* <div className="flex md:flex-col flex-row justify-start items-start gap-5 absolute bottom-0 left-10 md:relative"> */}
                <div className="w-10 h-10 relative">
                  <div className="w-10 h-10 left-0 top-0 absolute bg-neutral-100 rounded-full" />
                  <div className="w-6 h-6 left-[8px] top-[10px] absolute origin-top-left justify-center items-center inline-flex cursor-pointer">
                    <div className="w-6 h-6 relative" onClick={onClose}>
                      <img src={CloseIcon} alt="close icon" className="w-6 h-6 relative" />
                    </div>
                  </div>
                </div>
                <ReactToPrint
                  trigger={() => (
                    <div className="w-[140px] px-4 py-3 bg-neutral-50 rounded-[10px] justify-start items-center gap-2 inline-flex cursor-pointer md:w-[120px] sm:w-[100px]">
                      <img className="w-5 h-5 relative" src={printIcon} alt="print icon" />
                      <div className="text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight md:text-sm sm:text-xs">
                        Print
                      </div>
                    </div>
                  )}
                  content={() => printRef.current}
                />
                <div
                  className="w-[140px] px-4 py-3 bg-neutral-50 rounded-[10px] justify-start items-center gap-2 inline-flex cursor-pointer md:w-[120px] sm:w-[100px]"
                  onClick={generatePDF}
                >
                  <img className="w-5 h-5 relative" src={downloadIcon} alt="download icon" />
                  <div className="text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight md:text-sm sm:text-xs">
                    Download
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreditTokenModal;
