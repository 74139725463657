import React, { ReactElement, useRef } from "react";
import printIcon from "../../../assets/img/printIcon.svg";
import downloadIcon from "../../../assets/img/downloadIcon.svg";
import CloseIcon from "../../../assets/img/closeIcon.svg";
import CopyIcon from "../../../assets/img/document-copy.svg";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import FaanLogo from "../../../assets/img/faan-logo.png";
// import "./receiptModal.css"


interface ReceiptModalProps {
  onCloseModal: () => void;
  tokenData: any;
}

const ReceiptModal: React.FC<ReceiptModalProps> = ({
  onCloseModal,
  tokenData,
}): ReactElement => {
  const printRef = useRef<HTMLDivElement>(null);

  const copyNumber = () => {
    const numberToCopy = tokenData?.data?.token;
    navigator.clipboard
      .writeText(numberToCopy)
      .then(() => toast.success("token copied to clipboard"))
      .catch((error) => toast.error("Error copying text: ", error));
  };

  const generatePDF = () => {
    const input = printRef.current;

    if (!input) {
      console.error("Print reference is not available");
      return;
    }

    html2canvas(input).then((canvas: HTMLCanvasElement) => {
      const imgData = canvas.toDataURL("image/png");

      // Document of a4WidthMm wide and inputHeightMm high
      let pdf = new jsPDF("p", "pt", [input.offsetWidth, input.offsetHeight]);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      pdf.save(`receipt.pdf`);
    });
  };

  console.log(tokenData, "tokenData");

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 overflow-auto">
      <div className="w-[50%] h-auto bg-white rounded-[40px] absolute mt-[20%]">
        <div className="text-[#1c1f1d] text-[32px] font-bold font-['Uncut Sans Variable'] leading-10 text-center mt-3 mb-3">
          Token successfully generated
        </div>

        <div ref={printRef} className="w-[50%] h-auto relative bg-white rounded-lg border-8 border-[#f4f9f7] left-[25%] m-4 overflow-y-auto">
          <div className="left-20 flex flex-col items-center gap-3 mt-4">
            <img
              src={FaanLogo}
              alt=""
              // className=" mb-[140%] md:mb-0 print:mt-[-45%]"
              className="mb-[140%] md:mb-0 print:mt-0 print:mb-0"
            />
            <div className="flex flex-col items-center gap-1">
              <div className="text-center text-[#1c1f1d] text-sm font-semibold">
                Credit Token Receipt
              </div>
              <div className="text-[#1c1f1d]/70 text-xs">
              {moment(tokenData?.data.transactionDate).format("DD/MM/YYYY HH:mm:ss")}
              </div>
              <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }} className="px-3 py-1.5 rounded flex items-start gap-2 mb-2">
                <div className="text-black text-xs font-semibold">
                  {tokenData?.data.token}
                </div>
              </div>
            </div>
          </div>

          <div className="left-2 flex flex-col gap-1.5 px-2 mb-2">
            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Customer’s Name</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.customerName}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Address</div>
              <div className="text-right text-[#1c1f1d] text-xs capitalize">
              {tokenData?.data.address}
              </div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Tariff</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.tarrif}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Rate</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.rate}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Account No</div>
              <div className="text-right text-[#1c1f1d] text-xs">
                {tokenData?.data.accountNo}
              </div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Meter No</div>
              <div className="text-right text-[#1c1f1d] text-xs">
              {tokenData?.data?.meterNo}
              </div>
            </div>

            {/* <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Tariff: 1</div>
              <div className="text-right text-[#1c1f1d] text-xs">KRN: 1</div>
            </div> */}

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Operator ID</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.operator}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Printed on</div>
              <div className="text-right text-[#1c1f1d] text-xs">
              {moment(tokenData?.data.printedOn).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Transaction Date</div>
              <div className="text-right text-[#1c1f1d] text-xs">
              {moment(tokenData?.data.transactionDate).format("DD/MM/YYYY HH:mm:ss")}              
              </div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Receipt No</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.receiptNumber}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Cost of Units</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.unit}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1d]/10 flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Amount Tendered</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.amountTendered}</div>
            </div>

            <div className="w-full py-1 border-b border-[#1c1f1] flex justify-between">
              <div className="text-[#1c1f1d]/80 text-xs">Units (KWH)</div>
              <div className="text-right text-[#1c1f1d] text-xs">{tokenData?.data.units}</div>
            </div>
            <div className="left-[-4px] w-[301px] h-0 border-dotted border-[#000]"></div>

            <div className="w-[70%] text-xs">
              Surcharge for tampering is N100,000. Thank you for your patronage
            </div>
          </div>

        </div>

          {/* nested actions */}
          <div className="absolute md:top-4 md:left-[100%] left-[5%] flex md:flex-col flex-row justify-start items-start gap-4 px-2 bottom-[-10%]">
                <div className="w-10 h-10 relative">
                  <div className="w-10 h-10 left-0 top-0 absolute bg-neutral-100 rounded-full" />
                  <div className="w-6 h-6 left-[8px] top-[10px] absolute origin-top-left justify-center items-center inline-flex cursor-pointer">
                    <div className="w-6 h-6 relative" onClick={onCloseModal}>
                      <img src={CloseIcon} alt="close icon" className="w-6 h-6 relative" />
                    </div>
                  </div>
                </div>
                <ReactToPrint
                  trigger={() => (
                    <div className="w-[140px] px-4 py-3 bg-neutral-50 rounded-[10px] justify-start items-center gap-2 inline-flex cursor-pointer md:w-[120px] sm:w-[100px]">
                      <img className="w-5 h-5 relative" src={printIcon} alt="print icon" />
                      <div className="text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight md:text-sm sm:text-xs">
                        Print
                      </div>
                    </div>
                  )}
                  content={() => printRef.current}
                />
                <div
                  className="w-[140px] px-4 py-3 bg-neutral-50 rounded-[10px] justify-start items-center gap-2 inline-flex cursor-pointer md:w-[120px] sm:w-[100px]"
                  onClick={generatePDF}
                >
                  <img className="w-5 h-5 relative" src={downloadIcon} alt="download icon" />
                  <div className="text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight md:text-sm sm:text-xs">
                    Download
                  </div>
                </div>
              </div>
      </div>
    </div>
  );
};

export default ReceiptModal;
