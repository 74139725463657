import React, { ReactElement, useState, useEffect } from "react";
import { ButtonPrimary } from "../Button/Button";
import CloseIcon from "../../assets/img/closeIcon.svg";
import ReceiptModal from "./ReceiptModal/ReceiptModal";
import { generateToken } from "../../services/token";
import { Drawer} from 'antd';
import type { DrawerProps} from 'antd';
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { Spinner } from "../../components/Spinner/Spinner";
import { AxiosError } from "axios";
import moment from "moment";

interface IDrawer {
  isOpen?: boolean;
  setIsOpen: (open: boolean) => void; // Explicitly type setOpenModal
  apiData: any; // Define the type for your API data
  tokenData?: any;
  onGenerateTokenSuccess: (data: any) => void;
}

export function SideModal({
  isOpen,
  setIsOpen,
  apiData,
  onGenerateTokenSuccess
}: IDrawer): ReactElement {
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [tokenData, setTokenData] = useState<any>(null); // State to hold API data
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  const { mutateAsync: customerData, isLoading } = useMutation(generateToken, {
    onSuccess: async ({ data }) => {
        console.log("API response:", data); // Debugging line
        setTokenData(data);
        onGenerateTokenSuccess(data); // Pass data to the parent component
        setCustomerInfo(null);
      },
    onError: (error: AxiosError) => {
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message || error?.response?.data?.errors?.[0] ||
        "Unknown error occurred";
      toast.error(errorMessage);
    },
  });

  const handleGenerateToken = () => {
    customerData({
      amount: apiData.amount,
      invoiceNumber: apiData.invoiceNumber,
      meterNo: apiData.meterNo,
    });
  };


  useEffect(() => {
    setCustomerInfo(apiData);
  }, [apiData]);

  // useEffect(() => {
  //   if (showReceiptModal) {
  //     setIsOpen(false);
  //   }
  // }, [showReceiptModal, setIsOpen]);

  return (
    <>
      <Drawer
        placement={placement}
        width={600}
        onClose={() => setIsOpen(false)}
        closeIcon={null}      
        open={isOpen}
        >
        {customerInfo && (
          <>
            <div className="ml-[5%] mt-8 text-zinc-900 text-2xl font-bold font-['Uncut Sans Variable'] leading-tight">
              Customer Information
            </div>
            <div className="ml-[5%] mb-8 text-zinc-900 text-opacity-50 text-base font-medium font-['Uncut Sans Variable'] leading-tight">
              Review the table below before proceeding to generate a token{" "}
            </div>
            <div className="ml-[5%]  flex flex-col md:w-[552px] w-[325px] p-3 bg-stone-50 rounded-[20px]">
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Customer’s Name
                </div>
                <div className="text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.customerName}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Address
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.address}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Mobile No
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.mobileNo}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Meter No
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.meterNo}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Last Purchase
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {moment(apiData.lastPurchaseDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Invoice No
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.invoiceNumber}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Amount invoiced
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.amount}
                </div>
              </div>
              <div className="self-stretch py-2 border-b border-zinc-100 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Units
                </div>
                <div className="w-[264px] text-right text-zinc-900 text-sm font-bold font-['Uncut Sans Variable']">
                  {apiData.units}
                </div>
              </div>
              <div className="self-stretch py-2 justify-between items-center inline-flex">
                <div className="text-zinc-900 text-opacity-80 text-sm font-medium font-['Uncut Sans Variable']">
                  Payment status
                </div>
                <div className="w-[72px] px-4 py-2 bg-emerald-600 bg-opacity-5 rounded-[222px] justify-center items-center gap-2.5 flex">
                  <div className="text-right text-emerald-600  text-xs font-bold font-['Uncut Sans Variable']">
                    paid
                  </div>
                </div>
              </div>
            </div>
            <div className="w-10 h-10 md:left-[536px] left-[336px] top-[14px] absolute">
              <div
                className="w-6 h-6 left-[8px] top-[10px] absolute origin-top-left justify-center items-center inline-flex"
                onClick={() => {setIsOpen(!isOpen)}}
              >
                <img
                  src={CloseIcon}
                  alt="close icon"
                  className="w-6 h-6 relative"
                />
              </div>
            </div>
            <div className="md:ml-[45%] ml-[15%] mt-4">
              <ButtonPrimary
                label={isLoading ? <Spinner /> : "Generate token"}
                //   type="submit"
                handleClick={handleGenerateToken}
                // disabled={isLoading}
                width="300px"
              />
            </div>
          </>
        )}
      </Drawer>


      
      {/* {showReceiptModal && (
        <ReceiptModal
        onCloseModal={() => setShowReceiptModal(false)}
        tokenData={tokenData?.data}
        />
      )} */}
    </>
  );
}
