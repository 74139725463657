import React, { useState, useEffect } from "react";
import ContentLayout from "../../components/ContentLayout/ContentLayout";
import ReportIcon from "../../assets/img/reportIcon.svg";
import { getSavedState } from "../../services/localStorage";
import EiyeIcon from "../../assets/img/Eye.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Spinner } from "../../components/Spinner/Spinner";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { accountChangePassword } from "../../services/report";
import { Checkbox } from "antd";
import './settings.css'
import EyeSlashIcon from "../../assets/img/eyeslash.svg"

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(4)
    .max(15)
    .required("Current password is a required field"),
  newPassword: yup
    .string()
    .min(4)
    .max(15)
    .required("New password is a required field"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Confirm password must match new password")
    .required("Confirm password is a required field"),
});

const Settings: React.FC = () => {
  const savedState = getSavedState("EMSUser");
  const userName = savedState?.data?.fullName;
  const businessLocation = savedState?.data?.businessUnitName;
  const firstName = userName?.split(" ")[0];
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userEmail = savedState?.data?.email;
  const userPhoneNumber = savedState?.data?.phoneNumber;
  const [email, setEmail] = useState("");

  const [passwordRequirements, setPasswordRequirements] = useState({
    lowercase: false,
    uppercase: false,
    numbers: false,
    minimum: false,
  });

  const optionsWithDisabled = [
    { label: "Lowercase characters", value: "lowercase" },
    { label: "Uppercase characters", value: "uppercase" },
    { label: "numbers", value: "numbers" },
    { label: "8 characters minimum", value: "minimum" },
  ];

  const getInitials = (name: string) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const userInitials = getInitials(userName);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: authenticateUser, isLoading } = useMutation(
    accountChangePassword,
    {
      onSuccess: async ({ data }) => {
        toast.success("Password changed successfully");
        reset();
        setPasswordRequirements({
          lowercase: false,
          uppercase: false,
          numbers: false,
          minimum: false,
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          //@ts-ignore
          error?.response?.data?.message || error?.response?.data?.errors?.[0] ||
          "Unknown error occurred";
        toast.error(errorMessage);
      },
    }
  );

  const onSubmit = (data: any) => {
    console.log(data, "myData");

    authenticateUser({
      ...data,
    });
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setPasswordRequirements({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      numbers: /\d/.test(password),
      minimum: password.length >= 8,
    });
  };

  useEffect(() => {
    setEmail(userEmail || "");
  }, [userEmail]);

  return (
    <ContentLayout iconName={ReportIcon} headerName="Settings">
     <div className="w-full h-screen relative bg-white flex flex-col lg:flex-row justify-start items-start mt-6 gap-6 lg:gap-20 overflow-y-auto">
  <div className="flex-col justify-start items-start gap-4 inline-flex pl-4 lg:pl-[21px]">
    <div className="w-[70px] h-[70px] relative">
      <div className="w-[70px] h-[70px] left-0 top-0 absolute bg-[#F5F5F5] rounded-full border border-stone-50" />
      <div className="left-[21px] top-[20px] absolute text-zinc-900 text-2xl font-medium font-['Uncut Sans Variable']">
        {userInitials}
      </div>
    </div>
    <div className="flex-col justify-start items-start gap-1 flex">
      <div className="text-black text-2xl font-semibold font-['Uncut Sans Variable'] leading-[28.80px]">
        {userName}
      </div>
      <div className="text-zinc-900/opacity-50 text-sm font-medium font-['Uncut Sans Variable'] leading-[16.80px]">
        {businessLocation}
      </div>
    </div>

    <div className="w-[200px] h-6 p-2.5 bg-[#F5F5F5] rounded-lg border border-zinc-100 justify-start items-center gap-2.5 inline-flex">
      <div>
        <span className="text-zinc-900/opacity-40 text-xs font-normal font-['Uncut Sans Variable'] leading-[14.40px]">
          username
        </span>
        <span className="text-zinc-900/opacity-60 text-xs font-medium font-['Uncut Sans Variable'] leading-[14.40px]">
          {" "}
        </span>
        <span className="text-zinc-900 text-xs font-medium font-['Uncut Sans Variable'] leading-[14.40px]">
          {firstName}
        </span>
      </div>
    </div>
  </div>

  <div className="flex flex-col gap-10 w-full lg:w-auto justify-end">
    <div className="w-full lg:w-[610px] h-[287px] relative bg-[#F5F5F5] rounded-xl p-4 lg:p-[155px]">
      <div className="pl-5 pr-4 lg:pr-32 py-5 left-4 lg:left-[4px] top-20 lg:top-[77px] absolute bg-white rounded-[10px] flex-col justify-center items-start gap-6 inline-flex">
        <div className="w-full lg:w-[450px] mx-auto mb-3">
          <label className="block text-zinc-900 text-sm font-medium mb-2">
            Email{" "}
          </label>
          <input
            className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
            type="text"
            onFocus={(e) => e.target.removeAttribute("readonly")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </div>
        <div className="w-full lg:w-[450px] mx-auto mb-3">
          <label className="block text-zinc-900 text-sm font-medium mb-2">
            Phone number{" "}
          </label>
          <input
            className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
            type="text"
            autoComplete="new"
            onFocus={(e) => e.target.removeAttribute("readonly")}
            readOnly
            value={userPhoneNumber}
            disabled
          />
        </div>
      </div>
      <div className="left-4 lg:left-[20px] top-4 lg:top-[20px] absolute text-black text-xl font-medium font-['Uncut Sans Variable'] leading-normal">
        Contact info
      </div>
    </div>

    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full lg:w-[610px] h-auto lg:h-[468px] relative bg-[#F5F5F5] rounded-xl p-8 lg:p-0">
        <div className="pl-5 pr-4 lg:pr-32 py-5 left-4 lg:left-[4px] top-20 lg:top-[77px] absolute bg-white rounded-[10px] flex-col justify-center items-start gap-6 inline-flex">
          <div className="w-full lg:w-[450px] mx-auto mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Current Password{" "}
            </label>
            <div className="relative">
              <input
                className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
                type={showCurrentPassword ? "text" : "password"}
                autoComplete="new"
                onFocus={(e) => e.target.removeAttribute("readonly")}
                readOnly
                {...register("currentPassword")}
              />
              <img
            src={showCurrentPassword ? EiyeIcon : EyeSlashIcon}
            style={{ cursor: "pointer" }}
                alt="Toggle Password Visibility"
                width="15"
                height="15"
                onClick={toggleCurrentPasswordVisibility}
                className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer"
              />
            </div>
            <p className="text-xs text-red-600">
              {errors?.currentPassword?.message}
            </p>
          </div>
          <div className="w-full lg:w-[450px] mx-auto mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              New password{" "}
            </label>
            <div className="relative">
              <input
                className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
                type={showNewPassword ? "text" : "password"}
                autoComplete="new"
                onFocus={(e) => e.target.removeAttribute("readonly")}
                readOnly
                {...register("newPassword")}
                onChange={handleNewPasswordChange}
              />
              <img
            src={showNewPassword ? EiyeIcon : EyeSlashIcon}
            style={{ cursor: "pointer" }}
                alt="Toggle Password Visibility"
                width="15"
                height="15"
                onClick={toggleNewPasswordVisibility}
                className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer"
              />
            </div>
            <p className="text-xs text-red-600">
              {errors?.newPassword?.message}
            </p>
            <div className="w-full lg:w-[402px] h-[51px] mt-2">
              <Checkbox.Group
                options={optionsWithDisabled}
                value={Object.keys(passwordRequirements).filter(
                  (key) =>
                    passwordRequirements[key as keyof typeof passwordRequirements]
                )}
              />
            </div>
          </div>

          <div className="w-full lg:w-[450px] mx-auto mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Confirm new password{" "}
            </label>
            <div className="relative">
              <input
                className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="new"
                onFocus={(e) => e.target.removeAttribute("readonly")}
                readOnly
                disabled={!Object.values(passwordRequirements).every(Boolean)}
                {...register("confirmPassword")}
              />
              <img
            src={showConfirmPassword ? EiyeIcon : EyeSlashIcon}
            style={{ cursor: "pointer" }}
                alt="Toggle Password Visibility"
                width="15"
                height="15"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer"
              />
            </div>
            <p className="text-xs text-red-600">
              {errors?.confirmPassword?.message}
            </p>
          </div>
        </div>

        <div className="left-4 lg:left-[20px] top-4 lg:top-[20px] absolute text-black text-xl font-medium font-['Uncut Sans Variable'] leading-normal">
          Security
        </div>
        <div className="h-10 px-3.5 py-1.5 left-[60%] lg:left-[80%] top-4 lg:top-[20px] absolute bg-white rounded-[10px] shadow justify-start items-center gap-1 inline-flex">
          <button type="submit">
            <div className="text-center text-gray-700 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              {isLoading ? <Spinner /> : "Save"}
            </div>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

    </ContentLayout>
  );
};

export default Settings;
