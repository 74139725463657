import { FC, ReactNode } from "react";
import PolygonImage from "../../assets/img/background-vector.svg";
import './landingLayoutcss.css'



const LandingLayout: FC<{
  children: ReactNode;
  contentContainerClass?: string;
}> = ({ children, contentContainerClass }) => {
  return (
    <div className="w-full h-full relative bg-stone-50">
      <div className="flex justify-center items-center relative z-10 min-h-screen">
        <img src={PolygonImage} 
        alt="background" 
        className="w-auto h-auto"
        />
        <div 
        className={`w-[90%] max-w-[548px] h-auto lg:h-[700px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-white rounded-[20px] border py-4 md:py-[20px] lg:py-[30px] px-4 md:px-8 lg:px-[50px] ${contentContainerClass}`}
        // className={`w-[548px] h-[700px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-white rounded-[20px] border py-4 xl:py-[30px] px-8 xl:px-[50px] ${contentContainerClass}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
 
export default LandingLayout;



  